import * as Types from '../../../../generated/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ScienceSubmitQueryVariables = Types.Exact<{
  conferenceName: Types.Scalars['String'];
  groupId?: Types.Maybe<Types.Scalars['ID']>;
}>;


export type ScienceSubmitQuery = (
  { __typename?: 'Query' }
  & { conference?: Types.Maybe<(
    { __typename?: 'Conference' }
    & SciSubmitConferenceDataFragment
  )>, abstractGroup?: Types.Maybe<(
    { __typename?: 'AbstractGroup' }
    & AbstractGroupDataFragment
  )> }
);

export type SciSubmitConferenceDataFragment = (
  { __typename?: 'Conference' }
  & Pick<Types.Conference, 'id' | 'scienceSettings' | 'settings'>
  & { abstractSchema?: Types.Maybe<(
    { __typename?: 'NodeSchema' }
    & Pick<Types.NodeSchema, 'schema'>
  )> }
);

export type AbstractGroupDataFragment = (
  { __typename?: 'AbstractGroup' }
  & Pick<Types.AbstractGroup, 'id' | 'title' | 'category' | 'body'>
);

export type SubmitAbstractMutationVariables = Types.Exact<{
  conferenceName: Types.Scalars['String'];
  data: Types.Scalars['InputAbstractDataJsonType'];
  participantId?: Types.Maybe<Types.Scalars['ID']>;
  abstracts?: Types.Maybe<Array<Types.Scalars['InputAbstractDataJsonType']> | Types.Scalars['InputAbstractDataJsonType']>;
}>;


export type SubmitAbstractMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'submitAbstract'>
);

export const SciSubmitConferenceDataFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SciSubmitConferenceData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Conference"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"abstractSchema"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"schema"}}]}},{"kind":"Field","name":{"kind":"Name","value":"scienceSettings"}},{"kind":"Field","name":{"kind":"Name","value":"settings"}}]}}]} as unknown as DocumentNode<SciSubmitConferenceDataFragment, unknown>;
export const AbstractGroupDataFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AbstractGroupData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AbstractGroup"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"category"}},{"kind":"Field","name":{"kind":"Name","value":"body"}}]}}]} as unknown as DocumentNode<AbstractGroupDataFragment, unknown>;
export const ScienceSubmitDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ScienceSubmit"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"conferenceName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"groupId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"conference"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"shortName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"conferenceName"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SciSubmitConferenceData"}}]}},{"kind":"Field","name":{"kind":"Name","value":"abstractGroup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"idIn"},"value":{"kind":"ListValue","values":[{"kind":"Variable","name":{"kind":"Name","value":"groupId"}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AbstractGroupData"}}]}}]}},...SciSubmitConferenceDataFragmentDoc.definitions,...AbstractGroupDataFragmentDoc.definitions]} as unknown as DocumentNode<ScienceSubmitQuery, ScienceSubmitQueryVariables>;
export const SubmitAbstractDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SubmitAbstract"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"conferenceName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"InputAbstractDataJsonType"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"participantId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"abstracts"}},"type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"InputAbstractDataJsonType"}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"submitAbstract"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"conferenceName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"conferenceName"}}},{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}},{"kind":"Argument","name":{"kind":"Name","value":"participantId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"participantId"}}},{"kind":"Argument","name":{"kind":"Name","value":"abstracts"},"value":{"kind":"Variable","name":{"kind":"Name","value":"abstracts"}}}]}]}}]} as unknown as DocumentNode<SubmitAbstractMutation, SubmitAbstractMutationVariables>;